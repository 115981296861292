import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  getMessagesLoading: false,
  getMessagesError: false,
  getMessagesSuccess: false,
  messagesList: [],

  addMessageLoading: false,
  addMessageError: false,
  addMessageSuccess: false,

  editMessageLoading: false,
  editMessageError: false,
  editMessageSuccess: false,

  sendMessageLoading: false,
  sendMessageError: false,
  sendMessageSuccess: false,
  sendMessage: {},

  deleteMessageLoading: false,
  deleteMessageError: false,
  deleteMessageSuccess: false,
};

const map = {
  [`${actionTypes.GET_MESSAGES_LIST}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getMessagesLoading: true,
    getMessagesError: false,
    getMessagesSuccess: false,
  }),
  [`${actionTypes.GET_MESSAGES_LIST}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getMessagesLoading: false,
    getMessagesError: false,
    getMessagesSuccess: true,
    messagesList: payload.data
  }),
  [`${actionTypes.GET_MESSAGES_LIST}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getMessagesLoading: false,
    getMessagesError: true,
    getMessagesSuccess: false,
  }),
  // get messages reducer
  [`${actionTypes.ADD_MESSAGE}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    addMessageLoading: true,
    addMessageError: false,
    addMessageSuccess: false,
  }),
  [`${actionTypes.ADD_MESSAGE}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    addMessageLoading: false,
    addMessageError: false,
    addMessageSuccess: true
  }),
  [`${actionTypes.ADD_MESSAGE}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    addMessageLoading: false,
    addMessageError: true,
    addMessageSuccess: false,
  }),

  // get messages reducer
  [`${actionTypes.EDIT_MESSAGE}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    editMessageLoading: true,
    editMessageError: false,
    editMessageSuccess: false,
  }),
  [`${actionTypes.EDIT_MESSAGE}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    editMessageLoading: false,
    editMessageError: false,
    editMessageSuccess: true
  }),
  [`${actionTypes.EDIT_MESSAGE}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    editMessageLoading: false,
    editMessageError: true,
    editMessageSuccess: false,
  }),

  // add message reducer
  [`${actionTypes.SEND_MESSAGE}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    sendMessageLoading: true,
    sendMessageError: false,
    sendMessageSuccess: false,
  }),
  [`${actionTypes.SEND_MESSAGE}${actionTypes.FULFILLED}`]: (state,{payload}) => ({
    ...state,
    sendMessageLoading: false,
    sendMessageError: false,
    sendMessageSuccess: true,
    sendMessage: payload.data
  }),
  [`${actionTypes.SEND_MESSAGE}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    sendMessageLoading: false,
    sendMessageError: true,
    sendMessageSuccess: false,
  }),
  // send message reducer
  [`${actionTypes.DELETE_MESSAGE}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    deleteMessageLoading: true,
    deleteMessageError: false,
    deleteMessageSuccess: false,
  }),
  [`${actionTypes.DELETE_MESSAGE}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    deleteMessageLoading: false,
    deleteMessageError: false,
    deleteMessageSuccess: true
  }),
  [`${actionTypes.DELETE_MESSAGE}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    deleteMessageLoading: false,
    deleteMessageError: true,
    deleteMessageSuccess: false,
  }),
  // delete message reducer
  [actionTypes.CLEAN_MESSAGE_CACHE]:(state)=>({
    ...state,
    addMessageLoading: false,
    addMessageError: false,
    addMessageSuccess: false,

    editMessageLoading: false,
    editMessageError: false,
    editMessageSuccess: false,

    sendMessageLoading: false,
    sendMessageError: false,
    sendMessageSuccess: false,
    sendMessage: {},

    deleteMessageLoading: false,
    deleteMessageError: false,
    deleteMessageSuccess: false,
  })
};


export default function messagesReducer (state=defaultState, action) {
  return (map[action.type] && map[action.type] (state, action)) || state;
}