import { combineReducers } from "redux";

//reducers
import authReducer from "../modules/auth/authReducer";
import adminsReducer from "../modules/admins/adminsReducer";
import paymentsReducer from "../modules/payments/paymentsReducer";
import ordersReducer from "../modules/orders/ordersReducer";
import telegramUsersReducer from "../modules/telegramUsers/telegramUsersReducer";
import superCategoryReducer from "../modules/superCategory/superCategoryReducer";
import categoryReducer from "../modules/category/categoryReducer";
import clientsReducer from "../modules/clients/clientsReducer";
import messagesReducer from "../modules/messages/messagesReducer";
import historyReducer from "../modules/history/historyReducer";
import productItemReducer from "../modules/productItem/productItemReducer";
import {attachmentReducer} from "../modules/attachment/attachmentReducer";
import dashboardReducer from "../modules/dashboard/dashboardReducer";

//rootReducer
const reducer = combineReducers({
  authReducer,
  adminsReducer,
  paymentsReducer,
  ordersReducer,
  telegramUsersReducer,
  superCategoryReducer,
  categoryReducer,
  clientsReducer,
  messagesReducer,
  historyReducer,
  productItemReducer,
  attachmentReducer,
  dashboardReducer
 });

export default reducer;