import React from "react";
import PropTypes from "prop-types";
import { BubbleSpinLoader } from "react-css-loaders";


const SpinnerSmall = () => (
    <BubbleSpinLoader color={"#2D62ED"} duration={1.7} size={1} />
);

SpinnerSmall.propTypes = {
  color: PropTypes.string,
  number: PropTypes.number,
  size: PropTypes.number
};

export default SpinnerSmall;