  import React from "react";
import PropTypes from "prop-types";

import {
    HideToggleBox, 
    HideToggleIcon, 
    HideToggleText,
    Nav,
    NavbarLogo,
    NavbarLogoBox,
    NavbarWrapper,
    NavName,
    Navs
} from "./styles";

//icon
import {ReactComponent as Arrow} from "../../assets/icons/arrow.svg";
import logo from "../../assets/images/shop.png";

const SideBar = ({ nav, hidden, setHidden }) => {
  return (
    <NavbarWrapper>
      <NavbarLogoBox hide={hidden} to={"/"}>
          <NavbarLogo hide={hidden}>
            <img src={logo} alt="logo"/>
            <p>Food Corp</p>
          </NavbarLogo>
        </NavbarLogoBox>
      <Navs>
        {
          nav.map(({ link, name, icon }) => (
            <Nav to={link} key={name} hide={hidden}>
              <NavName className={"nav"} hide={hidden}>
                {icon && <img src={icon} alt={"icon"}/>}
                <p>{name}</p>
              </NavName>
            </Nav>
          ) )
        }
      </Navs>
      <HideToggleBox hide={hidden} onClick={setHidden}>
        <HideToggleText hide={hidden}>
          Нажмите здесь
        </HideToggleText>
        <HideToggleIcon hide={hidden}>
          <Arrow/>
        </HideToggleIcon>
      </HideToggleBox>
    </NavbarWrapper>
  );
};

SideBar.propTypes = {
  hidden: PropTypes.bool,
  nav: PropTypes.array,
  setHidden: PropTypes.func
};

export default SideBar;