import Home from "../assets/icons/home.svg";
import Star from "../assets/icons/star.svg";
import User from "../assets/icons/user.svg";
import History from "../assets/icons/history.svg";
import Payment from "../assets/icons/payment.svg";
import Product from "../assets/icons/product.svg";
import Order from "../assets/icons/order.svg";

export const navs =
    localStorage.getItem("token") && JSON.parse(atob(localStorage.getItem("token")?.split(".")?.[1]))?.role==="SUPER_ADMIN"?
  [
    {
      name: "Мониторинг",
      link: "/",
      icon: Home,
    },
    {
      name: "Заказы",
      link: "/orders",
      icon: Order,
    },
    {
      name: "Продукты",
      link: "/category",
      icon: Product,
    },
    {
      name: "Платежи",
      link: "/payments",
      icon: Payment,
    },
    {
      name: "Маркетинг",
      link: "/marketing",
      icon: Star,
    },
    {
      name: "Пользователи",
      link: "/users",
      icon: User,
    },
    {
      name: "История",
      link: "/history",
      icon: History,
    }
  ]:
  [
    {
      name: "Заказы",
      link: "/orders",
      icon: Order,
    },
    {
      name: "Платежи",
      link: "/payments",
      icon: Payment,
    },
    {
      name: "Пользователи",
      link: "/users",
      icon: User,
    },
    {
      name: "История",
      link: "/history",
      icon: History,
    }
  ]