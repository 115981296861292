import React from "react";

import { Container } from "./styles";

const Button = (props) => {
  const {loading, success, error, children} = props;
  return (
      <Container {...props} disabled={loading||success||error}>
        {loading?"Loading":success?"Success":error?"Error":children}
      </Container>
  );
};

export default Button;