import React from "react";

import { Container } from "./styles";

//component
import AuthForm from "../../components/Forms/AuthForm";

const AuthPage = () => {
  return (
    <Container>
      <AuthForm />
    </Container>
  );
};

export default AuthPage;