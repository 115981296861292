import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  getOrdersLoading: false,
  getOrdersError: false,
  getOrdersSuccess: false,
  ordersList: [],

  getUnpaidOrdersLoading: false,
  getUnpaidOrdersError: false,
  getUnpaidOrdersSuccess: false,
  unpaidOrdersList: [],

  addOrderLoading: false,
  addOrderError: false,
  addOrderSuccess: false,

  cancelOrderLoading: false,
  cancelOrderError: false,
  cancelSuccess: false,

  confirmedOrderLoading: false,
  confirmedOrderError: false,
  confirmedOrderSuccess: false,

  deliveredOrderLoading: false,
  deliveredOrderError: false,
  deliveredOrderSuccess: false,

  getUnViewedOrdersLoading: false,
  getUnViewedOrdersError: false,
  getUnViewedOrdersSuccess: false,
  getUnViewedOrdersList: [],

  unViewedOrdersLoading: false,
  unViewedOrdersError: false,
  unViewedOrdersSuccess: false,
  unViewedOrdersList: 0,

  getOnProgressOrdersLoading: false,
  getOnProgressOrdersError: false,
  getOnProgressOrdersSuccess: false,
  getOnProgressOrdersList: [],

  getOrderByPaymentIdLoading: false,
  getOrderByPaymentIdError: false,
  getOrderByPaymentSuccess: false,
  getOrderByPaymentIdList: [],

  orderPickedUpLoading: false,
  orderPickedUpError: false,
  orderPickedUpSuccess: false,

  orderPreparedLoading: false,
  orderPreparedError: false,
  orderPreparedSuccess: false,

  deleteOrderLoading: false,
  deleteOrderError: false,
  deleteOrderSuccess: false
};

const map = {
  [`${actionTypes.GET_ORDERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getOrdersLoading: true,
    getOrdersError: false,
    getOrdersSuccess: false
  }),
  [`${actionTypes.GET_ORDERS}${actionTypes.FULFILLED}`]: (state, { payload }) => ({
    ...state,
    ordersList: payload.data,
    getOrdersSuccess: true,
    getOrdersLoading: false,
    getOrderError: false
  }),
  [`${actionTypes.GET_ORDERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getOrdersError: true,
    getOrdersLoading: false,
    getOrdersSuccess: false
  }),
  // get orders reducer

  [`${actionTypes.GET_UNPAID_ORDERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getUnpaidOrdersLoading: true,
    getUnpaidOrdersError: false,
    getUnpaidOrdersSuccess: false,
  }),
  [`${actionTypes.GET_UNPAID_ORDERS}${actionTypes.FULFILLED}`]: (state, { payload }) => ({
    ...state,
    getUnpaidOrdersLoading: false,
    getUnpaidOrdersError: false,
    getUnpaidOrdersSuccess: true,
    unpaidOrdersList: payload.data,
  }),
  [`${actionTypes.GET_UNPAID_ORDERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getUnpaidOrdersLoading: false,
    getUnpaidOrdersError: true,
    getUnpaidOrdersSuccess: false,
  }),
  // get unpaid orders reducer


  [`${actionTypes.ADD_ORDER}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    addOrderLoading: true,
    addOrderError: false,
    addOrderSuccess: false
  }),
  [`${actionTypes.ADD_ORDER}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    addOrderSuccess: true,
    addOrderLoading: false,
    addOrderError: false
  }),
  [`${actionTypes.ADD_ORDER}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    addOrderError: true,
    addOrderLoading: false,
    addOrderSuccess: false
  }),
  // add orders reducer
  [`${actionTypes.CANCEL_ORDER}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    cancelOrderLoading: true,
    cancelOrderError: false,
    cancelSuccess: false
  }),
  [`${actionTypes.CANCEL_ORDER}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    cancelOrderLoading: false,
    cancelOrderError: false,
    cancelSuccess: true
  }),
  [`${actionTypes.CANCEL_ORDER}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    cancelOrderLoading: false,
    cancelOrderError: true,
    cancelSuccess: false
  }),
  // cancel order reducer
  [`${actionTypes.CONFIRMED_ORDER}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    confirmedOrderLoading: true,
    confirmedOrderError: false,
    confirmedOrderSuccess: false
  }),
  [`${actionTypes.CONFIRMED_ORDER}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    confirmedOrderLoading: false,
    confirmedOrderError: false,
    confirmedOrderSuccess: true
  }),
  [`${actionTypes.CONFIRMED_ORDER}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    confirmedOrderLoading: false,
    confirmedOrderError: true,
    confirmedOrderSuccess: false
  }),
  // confirmed order
  [`${actionTypes.DELIVERED_ORDER}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    deliveredOrderLoading: true,
    deliveredOrderError: false,
    deliveredOrderSuccess: false
  }),
  [`${actionTypes.DELIVERED_ORDER}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    deliveredOrderLoading: false,
    deliveredOrderError: false,
    deliveredOrderSuccess: true
  }),
  [`${actionTypes.DELIVERED_ORDER}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    deliveredOrderLoading: false,
    deliveredOrderError: true,
    deliveredOrderSuccess: false
  }),
  //delivered order reducer
  [`${actionTypes.GET_UNVIEWED_ORDERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getUnViewedOrdersLoading: true,
    getUnViewedOrdersError: false,
    getUnViewedOrdersSuccess: false,
  }),
  [`${actionTypes.GET_UNVIEWED_ORDERS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getUnViewedOrdersLoading: false,
    getUnViewedOrdersError: false,
    getUnViewedOrdersSuccess: true,
    getUnViewedOrdersList: payload.data
  }),
  [`${actionTypes.GET_UNVIEWED_ORDERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getUnViewedOrdersLoading: false,
    getUnViewedOrdersError: true,
    getUnViewedOrdersSuccess: false
  }),

  //delivered order reducer
  [`${actionTypes.UNVIEWED_ORDERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    unViewedOrdersLoading: true,
    unViewedOrdersError: false,
    unViewedOrdersSuccess: false,
  }),
  [`${actionTypes.UNVIEWED_ORDERS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    unViewedOrdersLoading: false,
    unViewedOrdersError: false,
    unViewedOrdersSuccess: true,
    unViewedOrdersList: payload.data
  }),
  [`${actionTypes.UNVIEWED_ORDERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    unViewedOrdersLoading: false,
    unViewedOrdersError: true,
    unViewedOrdersSuccess: false
  }),

  // get unViewed orders list
  [`${actionTypes.ON_PROGRESS_ORDERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
  getOnProgressOrdersLoading: true,
  getOnProgressOrdersError: false,
  getOnProgressOrdersSuccess: false
  }),
  [`${actionTypes.ON_PROGRESS_ORDERS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
  getOnProgressOrdersLoading: false,
  getOnProgressOrdersError: false,
  getOnProgressOrdersSuccess: true,
  getOnProgressOrdersList: payload.data
  }),
  [`${actionTypes.ON_PROGRESS_ORDERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
  getOnProgressOrdersLoading: false,
  getOnProgressOrdersError: true,
  getOnProgressOrdersSuccess: false,
  }),
  // get on progress orders
  [`${actionTypes.GET_ORDER_BY_PAYMENT_ID}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getOrderByPaymentIdLoading: true,
    getOrderByPaymentSuccess: false,
    getOrderByPaymentIdError: false
  }),
  [`${actionTypes.GET_ORDER_BY_PAYMENT_ID}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getOrderByPaymentIdLoading: false,
    getOrderByPaymentSuccess: true,
    getOrderByPaymentIdError: false,
    getOrderByPaymentIdList: payload.data
  }),
  [`${actionTypes.GET_ORDER_BY_PAYMENT_ID}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getOrderByPaymentIdLoading: false,
    getOrderByPaymentSuccess: false,
    getOrderByPaymentIdError: true,
  }),
  // get payment by order id reducer
  [`${actionTypes.ORDER_PICKED_UP}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    orderPickedUpLoading: true,
    orderPickedUpError: false,
    orderPickedUpSuccess: false
  }),
  [`${actionTypes.ORDER_PICKED_UP}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    orderPickedUpLoading: false,
    orderPickedUpError: false,
    orderPickedUpSuccess: true
  }),
  [`${actionTypes.ORDER_PICKED_UP}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    orderPickedUpLoading: false,
    orderPickedUpError: true,
    orderPickedUpSuccess: false
  }),
  // order picked-up reducer
  [`${actionTypes.ORDER_PREPARED}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    orderPreparedLoading: true,
    orderPreparedError: false,
    orderPreparedSuccess: false
  }),
  [`${actionTypes.ORDER_PREPARED}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    orderPreparedLoading: false,
    orderPreparedError: false,
    orderPreparedSuccess: true
  }),
  [`${actionTypes.ORDER_PREPARED}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    orderPreparedLoading: false,
    orderPreparedError: true,
    orderPreparedSuccess: false
  }),
  // order prepared reducer
  [`${actionTypes.DELETE_ORDER}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    deleteOrderLoading: true,
    deleteOrderError: false,
    deleteOrderSuccess: false
  }),
  [`${actionTypes.DELETE_ORDER}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    deleteOrderLoading: false,
    deleteOrderError: false,
    deleteOrderSuccess: true
  }),
  [`${actionTypes.DELETE_ORDER}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    deleteOrderLoading: false,
    deleteOrderError: true,
    deleteOrderSuccess: false
  }),
  // delete order reducer
  [actionTypes.CLEAN_ORDER_CACHE]: (state) => ({
    ...state,
    // getOrdersLoading: false,
    // getOrdersError: false,
    // getOrdersSuccess: false,

    addOrderLoading: false,
    addOrderError: false,
    addOrderSuccess: false,

    cancelOrderLoading: false,
    cancelOrderError: false,
    cancelSuccess: false,

    confirmedOrderLoading: false,
    confirmedOrderError: false,
    confirmedOrderSuccess: false,

    deliveredOrderLoading: false,
    deliveredOrderError: false,
    deliveredOrderSuccess: false,

    getUnviewedOrdersLoading: false,
    getUnviewedOrdersError: false,
    getUnViewedOrdersSuccess: false,

    getOnProgressOrdersLoading: false,
    getOnProgressOrdersError: false,
    getOnProgressOrdersSuccess: false,

    getOrderByPaymentIdLoading: false,
    getOrderByPaymentIdError: false,
    getOrderByPaymentSuccess: false,

    orderPickedUpLoading: false,
    orderPickedUpError: false,
    orderPickedUpSuccess: false,

    orderPreparedLoading: false,
    orderPreparedError: false,
    orderPreparedSuccess: false,

    deleteOrderLoading: false,
    deleteOrderError: false,
    deleteOrderSuccess: false
  }),
  // clean order reducer
};

export default function ordersReducer (state=defaultState, action) {
  return (map[action.type] && map[action.type] (state, action)) || state;
}