import React from "react";
import PropTypes from "prop-types";


import { Component } from "./styles";

class ErrorBoundary extends React.Component {
  constructor () {
    super();

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError () {
    // process the error
    return {
      hasError: true
    };
  }

  componentDidCatch (error) {
    console.log(error);
  }

  render () {
    if (this.hasError) {
      return (
        <Component>
          <span>This page is broken</span>
        </Component>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default ErrorBoundary;