import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  loginLoading: false,
  loginSuccess: false,
  loginError: false,
  logOut:false,

  getUsersLoading: false,
  getUsersSuccess: false,
  getUsersError: false,
  getUsers: [],

  getRolesLoading: false,
  getRolesSuccess: false,
  getRolesError: false,
  getRoles: [],
};

const map = {
  [`${actionTypes.POST_LOGIN}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    loginLoading: true,
    loginError: false,
    loginSuccess: false
  }),
  [`${actionTypes.POST_LOGIN}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    loginSuccess: true,
    loginLoading: false,
    loginError: false,
    logOut: false
  }),
  [`${actionTypes.POST_LOGIN}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    loginLoading: false,
    loginError: true,
    loginSuccess: false
  }),
  [actionTypes.LOGOUT]:(state)=>({
    ...state,
    logOut: true
  }),

  [`${actionTypes.GET_USERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getUsersLoading: true,
    getUsersSuccess: false,
    getUsersError: false,
  }),
  [`${actionTypes.GET_USERS}${actionTypes.FULFILLED}`]: (state,{payload}) => ({
    ...state,
    getUsersLoading: false,
    getUsersSuccess: true,
    getUsersError: false,
    getUsers: payload.data,
  }),
  [`${actionTypes.GET_USERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getUsersLoading: false,
    getUsersSuccess: false,
    getUsersError: true,
  }),

  [`${actionTypes.GET_ROLES}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getRolesLoading: true,
    getRolesSuccess: false,
    getRolesError: false,
  }),
  [`${actionTypes.GET_ROLES}${actionTypes.FULFILLED}`]: (state,{payload}) => ({
    ...state,
    getRolesLoading: false,
    getRolesSuccess: true,
    getRolesError: false,
    getRoles: payload.data,
  }),
  [`${actionTypes.GET_ROLES}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getRolesLoading: false,
    getRolesSuccess: false,
    getRolesError: true,
  }),

  [`${actionTypes.CHANGE_ROLE}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    changeRoleLoading: true,
    changeRoleSuccess: false,
    changeRoleError: false,
  }),
  [`${actionTypes.CHANGE_ROLE}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    changeRoleLoading: false,
    changeRoleSuccess: true,
    changeRoleError: false,
  }),
  [`${actionTypes.CHANGE_ROLE}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    changeRoleLoading: false,
    changeRoleSuccess: false,
    changeRoleError: true,
  }),

  [`${actionTypes.CHANGE_ENABLE}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    changeEnableLoading: true,
    changeEnableSuccess: false,
    changeEnableError: false,
  }),
  [`${actionTypes.CHANGE_ENABLE}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    changeEnableLoading: false,
    changeEnableSuccess: true,
    changeEnableError: false,
  }),
  [`${actionTypes.CHANGE_ENABLE}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    changeEnableLoading: false,
    changeEnableSuccess: false,
    changeEnableError: true,
  }),


};

export default function authReducer(state=defaultState, action) {
  return (map[action.type] && map[action.type] (state, action)) || state;
}