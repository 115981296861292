import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavbarWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const NavbarLogoBox = styled(Link)`
  width: 100%;
  height: 100px;
  background-color: #02CCF2;
  padding-left: ${({hide}) => hide ? "20px" : "20px"};
  display: flex;
  align-items: center;
  transition-duration: .5s;
  text-decoration: none;
  cursor: pointer;
  @media only screen and (max-width: 1050px) {
    height: 70px;
    padding-left: 17.5px;
  }
`;

export const NavbarLogo = styled.div`
  width: ${({hide}) => hide ? "60px" : "100%"};
  height: ${({hide}) => hide ? "60px" : "50px"};
  display: flex;
  align-items: center;
  overflow: hidden;
  transition-duration: .5s;

  img {
    height: 60%;
  }

  p {
    font-family: 'Lobster', cursive;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Pacifico', cursive;
    color: white;
    font-size: 20px;
    text-decoration: none;
    margin-left: 10px;
    margin-bottom: -3px;
    display: ${({hide}) => !hide ? "unset" : "none"};
  }

  @media only screen and (max-width: 1050px) {
    width: ${({hide}) => hide ? "40px" : "100%"};
    height: ${({hide}) => hide ? "40px" : "40px"};
  }
`;

export const Navs = styled.div`
  width: 100%;
  height: calc(100% - 180px);
  padding: 10px 0;
  overflow: auto;
  transition-duration: .5s;
  @media only screen and (max-width: 1050px) {
    height: calc(100% - 130px)
  }
  @media only screen and (max-width: 400px) {
    height: calc(100% - 70px)
  }
`;

export const Nav = styled(Link)`
  width: 100%;
  height: ${({hide}) => !hide ? "50px" : "70px"};
  padding: 10px 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  transition-duration: .5s;

  @media only screen and (max-width: 1050px) {
    height: ${({hide}) => !hide ? "50px" : "40px"};
  }
`;

export const NavName = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  transition-duration: .5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: ${({hide}) => hide ? "0" : "20px"};
    &:hover {
    p {
      color: #96B1F6;
    }
    img {
      color: #96B1F6;
    }
  }

  p {
    width: ${({hide}) => !hide ? "calc(100% - 25px)" : 0};
    height: fit-content;
    font-size: 18px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition-duration: .5s;
    color: white;
    margin-bottom: -5px;
  }

  img {
    width: ${({hide}) => !hide ? "25px" : "30px"};
    transition-duration: .5s;
    color: white;
  }

  @media only screen and (max-width: 1050px) {
    img {
      width: ${({hide}) => !hide ? "25px" : "25px"};
      margin-left: ${({hide}) => hide ? "22.5px" : "20px"};
    }
  }
`;

export const HideToggleBox = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 40px 40px 40px 0;
  transition-duration: .5s;
  position: relative;
  cursor: pointer;
  color: white;
  border: 1.5px solid #96B1F6;
  margin-top: 20px;

  &:hover {
    background-color: rgba(6, 69, 149, 0.62);
    border: 1.5px solid white;
    transition-duration: .5s;
  }

  @media only screen and (max-width: 1050px) {
    height: 60px;
  }
`;

export const HideToggleText = styled.div`
  ${({hide}) => !hide && "padding-left: 30px"};
  font-size: 20px;
  display: ${({hide}) => !hide ? "unset" : "none"};
  overflow: hidden;
`;

export const HideToggleIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 50px;
  transform: rotate(${({hide}) => !hide ? "0deg" : "180deg"});
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  svg {
    width: 20px;
  }

  &:hover {
    background-color: rgba(6, 69, 149, 0.62);
  }

  transition-duration: .5s;

  @media only screen and (max-width: 1050px) {
    width: 60px;
    height: 60px;
    padding: 0 12.5px;
    svg {
      width: 15px;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 40px;
    height: 40px;
    padding: 0 10px;
    position: fixed;
    top: 15px;
    left: ${({hide}) => hide ? 0 : "calc(100vw - 40px)"};
    z-index: 9999999999;
  }
`;