import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  tgUsersLoading: false,
  tgUsersError: false,
  tgUsersSuccess: false,
  tgUsersList: {},

  deliverersLoading: false,
  deliverersError: false,
  deliverersSuccess: false,
  deliverersList: [],

  getByPhoneNumbersLoading: false,
  getByPhoneNumbersError: false,
  getByPhoneNumbersSuccess: false,
  getByPhoneNumbersList: [],

  getByChatIdLoading: false,
  getByChatIdError: false,
  getByChatIdSuccess: false,
  getByChatId: {},

  tgUsersRoleLoading: false,
  tgUsersRoleError: false,
  tgUsersRoleSuccess: false
};


const map = {
  [`${actionTypes.GET_TELEGRAM_USERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    tgUsersLoading: true,
    tgUsersError: false,
    tgUsersSuccess: false
  }),
  [`${actionTypes.GET_TELEGRAM_USERS}${actionTypes.FULFILLED}`]: (state, { payload }) => ({
    ...state,
    tgUsersLoading: false,
    tgUsersError: false,
    tgUsersSuccess: true,
    tgUsersList: payload.data
  }),
  [`${actionTypes.GET_TELEGRAM_USERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    tgUsersLoading: false,
    tgUsersError: true,
    tgUsersSuccess: false,
  }),
  // get telegram users list reducer

  [`${actionTypes.GET_DELIVERERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    deliverersLoading: true,
    deliverersError: false,
    deliverersSuccess: false
  }),
  [`${actionTypes.GET_DELIVERERS}${actionTypes.FULFILLED}`]: (state, { payload }) => ({
    ...state,
    deliverersLoading: false,
    deliverersError: false,
    deliverersSuccess: true,
    deliverersList: payload.data
  }),
  [`${actionTypes.GET_DELIVERERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    deliverersLoading: false,
    deliverersError: true,
    deliverersSuccess: false,
  }),
  // get telegram users list reducer

  [`${actionTypes.GET_BY_PHONE_NUMBERS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getByPhoneNumbersLoading: true,
    getByPhoneNumbersError: false,
    getByPhoneNumbersSuccess: false
  }),
  [`${actionTypes.GET_BY_PHONE_NUMBERS}${actionTypes.FULFILLED}`]: (state, { payload }) => ({
    ...state,
    getByPhoneNumbersLoading: false,
    getByPhoneNumbersError: false,
    getByPhoneNumbersSuccess: true,
    getByPhoneNumbersList: payload.data
  }),
  [`${actionTypes.GET_BY_PHONE_NUMBERS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getByPhoneNumbersLoading: false,
    getByPhoneNumbersError: true,
    getByPhoneNumbersSuccess: false,
  }),
  // get telegram users list by phone numbers reducer

  [`${actionTypes.GET_BY_CHAT_ID}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getByChatIdLoading: true,
    getByChatIdError: false,
    getByChatIdSuccess: false
  }),
  [`${actionTypes.GET_BY_CHAT_ID}${actionTypes.FULFILLED}`]: (state, { payload }) => ({
    ...state,
    getByChatIdLoading: false,
    getByChatIdError: false,
    getByChatIdSuccess: true,
    getByChatId: payload.data
  }),
  [`${actionTypes.GET_BY_CHAT_ID}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getByChatIdLoading: false,
    getByChatIdError: true,
    getByChatIdSuccess: false,
  }),
  // get telegram users list by phone numbers reducer

  [`${actionTypes.CHANGE_TELEGRAM_USERS_ROLE}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    tgUsersRoleLoading: true,
    tgUsersRoleError: false,
    tgUsersRoleSuccess: false
  }),
  [`${actionTypes.CHANGE_TELEGRAM_USERS_ROLE}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    tgUsersRoleLoading: false,
    tgUsersRoleError: false,
    tgUsersRoleSuccess: true
  }),
  [`${actionTypes.CHANGE_TELEGRAM_USERS_ROLE}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    tgUsersRoleLoading: false,
    tgUsersRoleError: true,
    tgUsersRoleSuccess: false
  }),
  //chagne telegram users role reducer

  [`${actionTypes.CLEAN_TG_USERS_CACHE}`]: (state) => ({
    ...state,
    tgUsersRoleLoading: false,
    tgUsersRoleError: false,
    tgUsersRoleSuccess: false
  }),
};


export default function telegramUsersReducer (state=defaultState, action) {
  return (map[action.type] && map[action.type] (state, action)) || state;
}