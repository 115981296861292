import styled from "styled-components";

export const Form = styled.form`
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #02CCF2;
  border-radius: 16px;
  padding: 30px;
  background-color: #02CCF220;
  @media screen and (max-width: 450px) {
    width: 100vw;
    height: 100vh;
  }
`;

export const Heading = styled.h1`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  @media screen and (max-width: 450px) {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

export const Input = styled.input`
  width: 90%;
  height: 45px;
  outline: none;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: .5s;
  border: 1.5px solid #02CCF2;
  text-indent: 10px;
  @media screen and (max-width: 450px) {
    width: 95%;
    height: 40px;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 6px;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  color: grey;
  margin-bottom: 30px;
  margin-top: 20px;
  span {
    color: #02CCF2;
    font-weight: 500;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    margin-bottom: 20px;
  }
`;

export const PasswordToggle = styled.div`
  display: flex;
  margin-top: -50px;
  margin-left: auto;
  margin-right: 35px;
  font-size: 25px;
  cursor: pointer;
`;