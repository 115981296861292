import styled, {css} from "styled-components";

const getButtonStyles = props => {
    if (props.cancel) {
        return BtnCancel;
    } else if (props.complete) {
      return BtnComplete;
    }  else if (props.inProgress) {
        return BtnInProgress;
    } else if (props.btnCancelDeletion) {
      return BtnCancelDeletion;
    } else if (props.btnStart) {
      return BtnStart;
    } else if (props.btnAdd) {
      return BtnAdd;
    } else if (props.btnConfirm) {
        return BtnConfirm;
    } else if (props.btnAddUser) {
      return BtnAddUser;
    } else {
      return Container;
    }
};


export const BtnCancel = css`
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background-color: transparent;
  border: 1.5px solid #FF007C;
  color: #FF007C;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: .5s;
  margin-top: 5px;
  margin-bottom: 5px;
  &:hover {
    background-color: #FF007C80;
    color: black;
  }
`;

export const BtnComplete = css`
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background-color: transparent;
  border: 1.5px solid #00CCF2;
  color: #00CCF2;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: .5s;
  margin-top: 5px;
  margin-bottom: 5px;
  &:hover {
    background-color: #00CCF280;
    color: black;
  }
`;

export const BtnInProgress = css`
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background-color: transparent;
  border: 1.5px solid #00D9A6;
  color: #00D9A6;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: .5s;
  margin-top: 5px;
  margin-bottom: 5px;
  &:hover {
    background-color: #00D9A680;
    color: black;
  }
`;

export const BtnConfirm = css`
  width: 160px;
  height: 42px;
  outline: none;
  background-color: ${(props) => props.seeMore ? "#39D5CF" : "#00CCF2"};
  border: 16px;
  color: white;
  font-size: 16px;
  padding: 5px;
  border-radius: 25px;
  transition: .5s;
  margin-left: ${(props) => props.seeMore ? "auto" : " none"};
  margin-right: ${(props) => props.seeMore ? "auto" : " none"};
  margin-top: ${(props) => props.seeMore ? "-10px" : " none"};

  &:hover {
    background-color: white;
    border: 1.8px solid ${(props) => props.seeMore ? "#39D5CF" : "#00CCF2"};
    color: ${(props) => props.seeMore ? "#39D5CF" : "#00CCF2"};
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 42px;
    font-size: 15px;
    border-radius: ${(props) => props.btnSquare ? "8px" : "25px"};
  }
`;

export const BtnStart = css`
  width: 160px;
  height: 42px;
  outline: none;
  background-color: #00CCF2;
  border: 16px;
  color: white;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  transition: .5s;
  &:hover {
    background-color: transparent;
    border: 1.8px solid #00CCF2;
    color: #00CCF2;
  }
  @media screen and (max-width: 480px) {
    width: 120px;
    height: 38px;
    font-size: 14px;
  }
`;

export const BtnCancelDeletion = css`
  width: 160px;
  height: 42px;
  outline: none;
  background-color: #FF007C;
  border: 16px;
  color: white;
  font-size: 16px;
  padding: 5px;
  border-radius: 25px;
  transition: .5s;
  &:hover {
    background-color: white;
    border: 1.8px solid #FF007C;
    color: #FF007C;
  }
  @media screen and (max-width: 450px) {
    width: 130px;
    height: 40px;
    font-size: 15px;
  }
`;

export const BtnAdd = css`
  width: 160px;
  height: 42px;
  outline: none;
  background-color: #00CCF2;
  border: 16px;
  color: white;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  transition: .5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    width: 10px;
  }
  &:hover {
    background-color: transparent;
    border: 1.8px solid #00CCF2;
    color: #00CCF2;
  }
`;

export const BtnAddUser = css`
  width: 160px;
  height: 42px;
  outline: none;
  background-color: #00CCF2;
  border: 16px;
  color: white;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  transition: .5s;
  margin-left: auto;
  margin-right: 100px;
  &:hover {
    background-color: transparent;
    border: 1.8px solid #00CCF2;
    color: #00CCF2;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: 38px;
    font-size: 14px;
    margin-right: 0;
    margin-left: 30px;
  }
`;

export const Container = styled.button`
  width: 60px;
  height: 40px;
  font-size: 14px;
  outline: none;

 ${getButtonStyles}
`;
