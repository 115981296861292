import {actionTypes} from "../../../constants/actionTypes";


const defaultState = {
    getWeeklySalesLoading: false,
    getWeeklySalesSuccess: false,
    getWeeklySalesError: false,
    getWeeklySalesList: {},

    numberOfClientsAndUsersLoading: false,
    numberOfClientsAndUsersSuccess: false,
    numberOfClientsAndUsersError: false,
    numberOfClientsAndUsers: {},

    weeklyBestSellersLoading: false,
    weeklyBestSellersSuccess: false,
    weeklyBestSellersError: false,
    weeklyBestSellers: [],

    getAverageReviewLoading: false,
    getAverageReviewSuccess: false,
    getAverageReviewError: false,
    getAverageReview: {},

    getSmsLoading: false,
    getSmsSuccess: false,
    getSmsError: false,
    getSms:{},

    getTableOfReviewLoading: false,
    getTableOfReviewSuccess: false,
    getTableOfReviewError: false,
    getTableOfReview: [],
}

const map = {
    [`${actionTypes.GET_WEEKLY_SALES}${actionTypes.PENDING}`]: (state) => ({
			...state,
			getWeeklySalesLoading:true,
			getWeeklySalesSuccess:false,
			getWeeklySalesError:false,
    }),
    [`${actionTypes.GET_WEEKLY_SALES}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
			...state,
			getWeeklySalesLoading:false,
			getWeeklySalesSuccess:true,
			getWeeklySalesError:false,
			getWeeklySalesList:payload.data,
    }),
    [`${actionTypes.GET_WEEKLY_SALES}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			getWeeklySalesLoading:false,
			getWeeklySalesSuccess:false,
			getWeeklySalesError:true,
    }),

    [`${actionTypes.NUMBER_OF_CLIENTS_AND_USERS}${actionTypes.PENDING}`]: (state) => ({
			...state,
			numberOfClientsAndUsersLoading:true,
			numberOfClientsAndUsersSuccess:false,
			numberOfClientsAndUsersError:false,
    }),
    [`${actionTypes.NUMBER_OF_CLIENTS_AND_USERS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
			...state,
			numberOfClientsAndUsersLoading:false,
			numberOfClientsAndUsersSuccess:true,
			numberOfClientsAndUsersError:false,
			numberOfClientsAndUsers:payload.data,
    }),
    [`${actionTypes.NUMBER_OF_CLIENTS_AND_USERS}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			numberOfClientsAndUsersLoading:false,
			numberOfClientsAndUsersSuccess:false,
			numberOfClientsAndUsersError:true,
    }),

    [`${actionTypes.WEEKLY_BEST_SELLERS}${actionTypes.PENDING}`]: (state) => ({
			...state,
			weeklyBestSellersLoading:true,
			weeklyBestSellersSuccess:false,
			weeklyBestSellersError:false,
    }),
    [`${actionTypes.WEEKLY_BEST_SELLERS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
			...state,
			weeklyBestSellersLoading:false,
			weeklyBestSellersSuccess:true,
			weeklyBestSellersError:false,
			weeklyBestSellers:payload.data,
    }),
    [`${actionTypes.WEEKLY_BEST_SELLERS}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			weeklyBestSellersLoading:false,
			weeklyBestSellersSuccess:false,
			weeklyBestSellersError:true,
    }),

    [`${actionTypes.GET_AVERAGE_REVIEW}${actionTypes.PENDING}`]: (state) => ({
			...state,
			getAverageReviewLoading:true,
			getAverageReviewSuccess:false,
			getAverageReviewError:false,
    }),
    [`${actionTypes.GET_AVERAGE_REVIEW}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
			...state,
			getAverageReviewLoading:false,
			getAverageReviewSuccess:true,
			getAverageReviewError:false,
			getAverageReview:payload.data,
    }),
    [`${actionTypes.GET_AVERAGE_REVIEW}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			getAverageReviewLoading:false,
			getAverageReviewSuccess:false,
			getAverageReviewError:true,
    }),

    [`${actionTypes.GET_SMS}${actionTypes.PENDING}`]: (state) => ({
			...state,
			getSmsLoading:true,
			getSmsSuccess:false,
			getSmsError:false,
    }),
    [`${actionTypes.GET_SMS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
			...state,
			getSmsLoading:false,
			getSmsSuccess:true,
			getSmsError:false,
			getSms:payload.data,
    }),
    [`${actionTypes.GET_SMS}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			getSmsLoading:false,
			getSmsSuccess:false,
			getSmsError:true,
    }),

    [`${actionTypes.GET_TABLE_OF_REVIEW}${actionTypes.PENDING}`]: (state) => ({
			...state,
			getTableOfReviewLoading:true,
			getTableOfReviewSuccess:false,
			getTableOfReviewError:false,
    }),
    [`${actionTypes.GET_TABLE_OF_REVIEW}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
			...state,
			getTableOfReviewLoading:false,
			getTableOfReviewSuccess:true,
			getTableOfReviewError:false,
			getTableOfReview:payload.data,
    }),
    [`${actionTypes.GET_TABLE_OF_REVIEW}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			getTableOfReviewLoading:false,
			getTableOfReviewSuccess:false,
			getTableOfReviewError:true,
    }),
};


export default function dashboardReducer (state=defaultState, action) {
    return (map[action.type] && map[action.type] (state, action)) || state;
}