import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  getSuperCategoryLoading: false,
  getSuperCategoryError: false,
  getSuperCategorySuccess: false,
  categoryData: [],

  addSuperCategoryLoading: false,
  addSuperCategoryError: false,
  addSuperCategorySuccess: false,

  editSuperCategoryLoading: false,
  editSuperCategoryError: false,
  editSuperCategorySuccess: false,

  deleteSuperCategoryLoading: false,
  deleteSuperCategoryError: false,
  deleteSuperCategorySuccess: false
};


const map = {
  [`${actionTypes.GET_SUPER_CATEGORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getSuperCategoryLoading: true,
    getSuperCategoryError: false,
    getSuperCategorySuccess: false,
  }),
  [`${actionTypes.GET_SUPER_CATEGORY}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getSuperCategoryLoading: false,
    getSuperCategoryError: false,
    getSuperCategorySuccess: true,
    categoryData: payload.data
  }),
  [`${actionTypes.GET_SUPER_CATEGORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getSuperCategoryLoading: false,
    getSuperCategoryError: true,
    getSuperCategorySuccess: false,
  }), // get super category reducer

  [`${actionTypes.ADD_SUPER_CATEGORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    addSuperCategoryLoading: true,
    addSuperCategoryError: false,
    addSuperCategorySuccess: false,
  }),
  [`${actionTypes.ADD_SUPER_CATEGORY}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    addSuperCategoryLoading: false,
    addSuperCategoryError: false,
    addSuperCategorySuccess: true,
  }),
  [`${actionTypes.ADD_SUPER_CATEGORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    addSuperCategoryLoading: false,
    addSuperCategoryError: true,
    addSuperCategorySuccess: false,
  }),
  //add category reducer
  [`${actionTypes.EDIT_SUPER_CATEGORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    editSuperCategoryLoading: true,
    editSuperCategoryError: false,
    editSuperCategorySuccess: false,
  }),
  [`${actionTypes.EDIT_SUPER_CATEGORY}${actionTypes.FULFILLED}`]: (state) => ({
      ...state,
      editSuperCategoryLoading: false,
      editSuperCategoryError: false,
      editSuperCategorySuccess: true,
  }),
  [`${actionTypes.EDIT_SUPER_CATEGORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    editSuperCategoryLoading: false,
    editSuperCategoryError: true,
    editSuperCategorySuccess: false,
  }),
  //edit super category reducer
  //delete super category reducer
  [`${actionTypes.DELETE_SUPER_CATEGORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    deleteSuperCategoryLoading: true,
    deleteSuperCategoryError: false,
    deleteSuperCategorySuccess: false,
  }),
      [`${actionTypes.DELETE_SUPER_CATEGORY}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    deleteSuperCategoryLoading: false,
    deleteSuperCategoryError: false,
    deleteSuperCategorySuccess: true,
  }),
      [`${actionTypes.DELETE_SUPER_CATEGORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    deleteSuperCategoryLoading: false,
    deleteSuperCategoryError: true,
    deleteSuperCategorySuccess: false,
  }),
  [actionTypes.CLEAN_SUPER_CATEGORY_CACHE]:(state)=>({
    ...state,

    addSuperCategoryLoading: false,
    addSuperCategoryError: false,
    addSuperCategorySuccess: false,

    editSuperCategoryLoading: false,
    editSuperCategoryError: false,
    editSuperCategorySuccess: false,

    deleteSuperCategoryLoading: false,
    deleteSuperCategoryError: false,
    deleteSuperCategorySuccess: false
  })
};

export default function SuperCategoryReducer (state=defaultState, action) {
  return(map[action.type] && map[action.type] (state, action)) || state;
}