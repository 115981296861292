import React, { useState, useEffect, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import {
  NavbarContainer,
  PageContainer,
  WrapperMain
} from "./styles";

//components
import SideBar from "../../components/SideBar";
//mock
import { navs } from "../../mocks/route";
//hook
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {unViewedOrders} from "../../redux/modules/orders/ordersAction";
import {useDispatch, useSelector} from "react-redux";

// audio
import ringtone from "../../assets/audio/Iphone Ting Message Tone.mp3"

//pages
const DashboardPage = lazy(() => import("../DashboardPage"));
const HistoryPage = lazy(() => import("../HistoryPage"));
const OrderPage = lazy(() => import("../OrdersPage"));
const PaymentsPage = lazy(() => import("../PaymentsPage"));
const SendSMSPage = lazy(() => import("../SendSMSPage"));
const UsersPage = lazy(() => import("../UsersPage"));
const SuperCategoryPage = lazy(() => import("../SuperCategoryPage"));
const CategoryPage = lazy(() => import("../CategoryPage"));
const ProductItemPage = lazy(() => import("../ProductItemPage"));
const AuthPage = lazy(() => import("../AuthPage"));


const HomePage = () => {
  const [hidden, setHidden] = useState(true);
  const [didMount,setDidMount] = useState(false);
  const width = useWindowDimensions().width;
  const audio = new Audio(ringtone);


  const dispatch = useDispatch();

  const numberOfUnViewedOrders = useSelector(state => state.ordersReducer)

  useEffect(() => {
    width > 1300 && setHidden(false);
  }, []);

    useEffect(()=>{
        const interval  = setInterval(() => {
          dispatch(unViewedOrders())
        }, 15000)
        return () => {
          clearInterval(interval);
        }
    },[])

    useEffect(() => {
        dispatch(unViewedOrders())
        const timeout = setTimeout(() => {
        setDidMount(true);
          console.log("did mount")
        }, 1000)
        return () => {
          clearTimeout(timeout);
        }
    },[])

    useEffect(() => {
        // audio.autoplay;
        if (numberOfUnViewedOrders.unViewedOrdersList > 0 && didMount){
          audio.play();
        }
    },[numberOfUnViewedOrders.unViewedOrdersList])

  return (
    <WrapperMain>
      <NavbarContainer hide={hidden}>
          <SideBar nav={navs} hidden={hidden} setHidden={() => setHidden(!hidden)}/>
      </NavbarContainer>
      <PageContainer hide={hidden} id={"admin-content"}>
        <Routes>
          {localStorage.getItem("token") && JSON.parse(atob(localStorage.getItem("token")?.split(".")[1])).role==="SUPER_ADMIN"?
            <>
              <Route exact path="/" element={<DashboardPage/>} />
              <Route exact path="/category" element={<SuperCategoryPage/>} />
              <Route exact path="/category/:superCategoryId/sub-category" element={<CategoryPage/>} />
              <Route exact path="/category/:superCategoryId/sub-category/:categoryId" element={<ProductItemPage />} />
              <Route exact path="/marketing" element={<SendSMSPage/>} />
            </>
            :""
          }
          <Route exact path="/history" element={<HistoryPage/>} />
          <Route exact path="/orders" element={<OrderPage/>} />
          <Route exact path="/payments" element={<PaymentsPage/>} />
          <Route exact path="/users" element={<UsersPage/>} />
          <Route exact path="/login" element={<AuthPage />} />
        </Routes>
      </PageContainer>
    </WrapperMain>
  );
};

export default HomePage;