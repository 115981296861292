import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import { 
  Form, 
  Input, 
  Heading, 
  Subtitle, 
  PasswordToggle 
} from "./styles";

//action
import { postLogin } from "../../../redux/modules/auth/authAction";

//component
import Button from "../../Button";
import SpinnerSmall from "../../SpinnerSmall";

const AuthForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("text");

  //redux connect
  const dispatch = useDispatch(); 
  const list = useSelector(state => state.authReducer);
  const {loginLoading, loginSuccess, loginError} = list;


  //handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postLogin({username, password}));
  };

  //handleShowPassword
  const handleShowPassword = () => {
    if (passwordType === "text") {
      setPasswordType("password");
    } else {
      setPasswordType("text");
    }
  };


  return (
    <Form onSubmit={handleSubmit}>
      <Heading>👋 Hello, новичок, присоединяйся!</Heading>
      <Input 
          type="text" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          placeholder="Имя пользователя" 
          required
          min={10}
          max={20}
      />
      <Input
          type={passwordType}
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Пароль" 
          required
          min={5}
          max={15}
      />
      <PasswordToggle onClick={handleShowPassword}>
        {passwordType === "text" ? "🙉" : "🙈"}
      </PasswordToggle>
      <Subtitle>
          Если у вас возникли какие-либо проблемы с <br/>
          входом в систему, свяжитесь с <span>@admin_corpfood</span>
      </Subtitle>
      <Button btnConfirm>
        {
          !loginError & 
          !loginSuccess & 
          loginLoading ? <SpinnerSmall /> : "Отправить"
        }
      </Button>
    </Form>
  );
};

export default AuthForm;