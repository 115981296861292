export const actionTypes = {
  GET_TOKEN: "GET_TOKEN",
  POST_LOGIN: "POST_LOGIN",
  ADD_ADMIN: "ADD_ADMIN",
  LOGOUT: "LOGOUT",
  GET_USERS: "GET_USERS",
  GET_ROLES: "GET_ROLES",
  CHANGE_ROLE: "CHANGE_ROLE",
  CHANGE_ENABLE: "CHANGE_ENABLE",

  // PAYMENTS
  GET_PAYMENTS: "GET_PAYMENTS",
  ADD_PAYMENTS: "ADD_PAYMENTS",
  PAID_FOR_ORDER: "PAID_FOR_ORDER",
  CLEAN_PAYMENT_CACHE: "CLEAN_PAYMENT_CACHE",

  // ORDERS
  GET_ORDERS: "GET_ORDERS",
  GET_UNPAID_ORDERS: "GET_UNPAID_ORDERS",
  ADD_ORDER: "ADD_ORDER",
  CANCEL_ORDER: "CANCEL_ORDER",
  CONFIRMED_ORDER: "CONFIRMED_ORDER",
  DELIVERED_ORDER: "DELIVERED_ORDER",
  GET_UNVIEWED_ORDERS: "GET_UNVIEWED_ORDERS",
  UNVIEWED_ORDERS: "UNVIEWED_ORDERS",
  ON_PROGRESS_ORDERS: "ON_PROGRESS_ORDERS",
  GET_ORDER_BY_PAYMENT_ID: "GET_ORDER_BY_PAYMENT_ID",
  ORDER_PICKED_UP: "ORDER_PICKED_UP",
  ORDER_PREPARED: "ORDER_PREPARED",
  DELETE_ORDER: "DELETE_ORDER",
  CLEAN_ORDER_CACHE: "CLEAN_ORDER_CACHE",
  // HISTORY
  GET_HISTORY: "GET_HISTORY",
  // TELEGRAM_USERS
  GET_TELEGRAM_USERS: "GET_TELEGRAM_USERS",
  CHANGE_TELEGRAM_USERS_ROLE: "CHANGE_TELEGRAM_USERS_ROLE",
  GET_DELIVERERS: "GET_DELIVERERS",
  GET_BY_PHONE_NUMBERS: "GET_BY_PHONE_NUMBERS",
  GET_BY_CHAT_ID: "GET_BY_CHAT_ID",
  CLEAN_TG_USERS_CACHE: "CLEAN_TG_USERS_CACHE",
  // CLIENTS
  GET_CLIENTS: "GET_CLIENTS",
  // SUPER CATEGORY
  ADD_SUPER_CATEGORY: "ADD_SUPER_CATEGORY",
  GET_SUPER_CATEGORY: "GET_SUPER_CATEGORY",
  EDIT_SUPER_CATEGORY: "EDIT_SUPER_CATEGORY",
  DELETE_SUPER_CATEGORY: "DELETE_SUPER_CATEGORY",
  CLEAN_SUPER_CATEGORY_CACHE:"CLEAN_SUPER_CATEGORY_CACHE",
  // CATEGORY
  GET_CATEGORY_BY_ID: "GET_CATEGORY_BY_ID",
  ADD_CATEGORY: "ADD_CATEGORY",
  EDIT_CATEGORY: "EDIT_CATEGORY",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  CLEAN_CATEGORY_CACHE: "CLEAN_CATEGORY_CACHE",
  // MESSAGES
  GET_MESSAGES_LIST: "GET_MESSAGES_LIST",
  ADD_MESSAGE: "ADD_MESSAGE",
  SEND_MESSAGE: "SEND_MESSAGE",
  DELETE_MESSAGE: "DELETE_MESSAGE",
  EDIT_MESSAGE: "EDIT_MESSAGE",
  CLEAN_MESSAGE_CACHE: "CLEAN_MESSAGE_CACHE",
  // PRODUCT ITEMS
  GET_PRODUCT_ITEMS_LIST: "GET_PRODUCT_ITEMS_LIST",
  GET_PRODUCT_ITEMS_BY_CATEGORY_ID: "GET_PRODUCT_ITEMS_BY_CATEGORY_ID",
  ADD_PRODUCT_ITEM: "ADD_PRODUCT_ITEM",
  EDIT_PRODUCT_ITEM: "EDIT_PRODUCT_ITEM",
  DELETE_PRODUCT_ITEM: "DELETE_PRODUCT_ITEM",
  CLEAN_PRODUCT_ITEM: "CLEAN_PRODUCT_ITEM",
  CHANGE_AVAILABILITY: "CHANGE_AVAILABILITY",
  //ATTACHMENT
  ADD_ATTACHMENT: "ADD_ATTACHMENT",
  DELETE_ATTACHMENT: "DELETE_ATTACHMENT",
  CLEAN_ATTACHMENT_ID: "CLEAN_ATTACHMENT_ID",
  //DASHBOARD
  GET_WEEKLY_SALES: "GET_WEEKLY_SALES",
  NUMBER_OF_CLIENTS_AND_USERS: "NUMBER_OF_CLIENTS_AND_USERS",
  WEEKLY_BEST_SELLERS: "WEEKLY_BEST_SELLERS",
  GET_AVERAGE_REVIEW: "GET_AVERAGE_REVIEW",
  GET_TABLE_OF_REVIEW:"GET_TABLE_OF_REVIEW",
  GET_SMS: "GET_SMS",
  // STATUS
  FULFILLED: "_FULFILLED",
  PENDING: "_PENDING",
  REJECTED: "_REJECTED",


};