import {actionTypes} from "../../../constants/actionTypes";

const defaultState = {
	addAttachmentLoading: false,
	addAttachmentSuccess: false,
	addAttachmentError: false,
	addAttachmentId: "",
	deleteAttachmentLoading: false,
	deleteAttachmentSuccess: false,
	deleteAttachmentError: false,
};

const map = {
    [`${actionTypes.CLEAN_ATTACHMENT_ID}`]:(state)=>({
			...state,
			addAttachmentId: ""
    }),
    [`${actionTypes.ADD_ATTACHMENT}${actionTypes.PENDING}`]: (state) => ({
			...state,
			addAttachmentLoading: true,
			addAttachmentError: false,
			addAttachmentSuccess: false,
    }),
    [`${actionTypes.ADD_ATTACHMENT}${actionTypes.FULFILLED}`]: (state,{payload}) => ({
			...state,
			addAttachmentLoading: false,
			addAttachmentError: false,
			addAttachmentSuccess: true,
			addAttachmentId:payload.data
    }),
    [`${actionTypes.ADD_ATTACHMENT}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			addAttachmentLoading: false,
			addAttachmentError: true,
			addAttachmentSuccess: false,
    }),
    [`${actionTypes.DELETE_ATTACHMENT}${actionTypes.PENDING}`]: (state) => ({
			...state,
			deleteAttachmentLoading: true,
			deleteAttachmentError: false,
			deleteAttachmentSuccess: false,
    }),
    [`${actionTypes.DELETE_ATTACHMENT}${actionTypes.FULFILLED}`]: (state) => ({
			...state,
			deleteAttachmentLoading: false,
			deleteAttachmentError: false,
			deleteAttachmentSuccess: true,
			addAttachmentId:""
    }),
    [`${actionTypes.DELETE_ATTACHMENT}${actionTypes.REJECTED}`]: (state) => ({
			...state,
			deleteAttachmentLoading: false,
			deleteAttachmentError: true,
			deleteAttachmentSuccess: false,
    })
};

export const attachmentReducer = (state = defaultState, action) => {
  return(map[action.type] && map[action.type] (state, action)) || state;
};