import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container } from "./AppStyles";

//components
import ErrorBoundary from "./components/ErrorBoundary";
import Spinner from "./components/Spinner";
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import {useLocation, useNavigate} from "react-router";

/* eslint-disable no-restricted-globals */

// finished the project
const App = () => {
  const [login, setLogin] = useState(false);


  //redux hooks
  const list = useSelector(state => state.authReducer);
  const { loginLoading, loginSuccess, loginError } = list;
  console.log(loginError, "loginError");
  console.log(loginSuccess, "loginSuccess");
  console.log(loginLoading, "loginLoading");

  const navigate = useNavigate();
  const myLocation = useLocation();

  // check if token there
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLogin(true);
      if (myLocation.pathname==="/login"){
        navigate("/");
        location.reload();
      }
    } else {
      setLogin(false);
      navigate("/login");
    }
  }, [myLocation.pathname, navigate]);


  return (
    <Container>
      <ErrorBoundary>
        <Suspense fallback={<Spinner/>}>
          {
            login ? <HomePage /> : <AuthPage />
          }
        </Suspense>
      </ErrorBoundary>
    </Container>
  );
};

export default App;