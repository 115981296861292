import React from "react";
import PropTypes from "prop-types";
import { BubbleSpinLoader } from "react-css-loaders";

import { Container } from "./styles";

const Spinner = () => (
  <Container>
    <BubbleSpinLoader color={"#0079B6"} duration={1.7} size={9} />
  </Container>
);

Spinner.propTypes = {
  color: PropTypes.string,
  number: PropTypes.number,
  size: PropTypes.number
};

export default Spinner;