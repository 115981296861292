import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  getPaymentsLoading: false,
  getPaymentsError: false,
  getPaymentsSuccess: false,
  paymentsList: {},

  addPaymentLoading: false,
  addPaymentError: false,
  addPaymentSuccess: false,

  paidForOrderLoading: false,
  paidForOrderError: false,
  paidForOrderSuccess: false
};


const map = {
  [`${actionTypes.GET_PAYMENTS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getPaymentsLoading: true,
    getPaymentsError: false,
    getPaymentsSuccess: false
  }),
  [`${actionTypes.GET_PAYMENTS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getPaymentsSuccess: true,
    getPaymentsLoading: false,
    getPaymentsError: false,
    paymentsList: payload.data,
  }),
  [`${actionTypes.GET_PAYMENTS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getPaymentsLoading: false,
    getPaymentsError: true,
    getPaymentsSuccess: false
  }),
  // get payments reducer
  [`${actionTypes.ADD_PAYMENTS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    addPaymentsLoading: true,
    addPaymentsError: false,
    addPaymentsSuccess: false
  }),
  [`${actionTypes.ADD_PAYMENTS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    paymentsList: payload.data,
    addPaymentsSuccess: true,
    addPaymentsLoading: false,
    addPaymentsError: false
  }),
  [`${actionTypes.ADD_PAYMENTS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    addPaymentsLoading: false,
    addPaymentsError: true,
    addPaymentsSuccess: false
  }),
  // add payments list
  [`${actionTypes.PAID_FOR_ORDER}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    paidForOrderLoading: true,
    paidForOrderError: false,
    paidForOrderSuccess: false
  }),
  [`${actionTypes.PAID_FOR_ORDER}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    paidForOrderSuccess: true,
    paidForOrderLoading: false,
    paidForOrderError: false
  }),
  [`${actionTypes.PAID_FOR_ORDER}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    paidForOrderLoading: false,
    paidForOrderError: true,
    paidForOrderSuccess: false
  }),
  // paid for order
  [actionTypes.CLEAN_PAYMENT_CACHE]:(state)=>({
    ...state,
    addPaymentLoading: false,
    addPaymentError: false,
    addPaymentSuccess: false,

    paidForOrderLoading: false,
    paidForOrderError: false,
    paidForOrderSuccess: false
  })
};


export default function paymentsReducer (state=defaultState, action) {
  return (map[action.type] && map[action.type] (state, action)) || state;
}