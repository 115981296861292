import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  getHistoryLoading: false,
  getHistoryError: false,
  getHistorySuccess: false,
  getHistoryList: []
};


const map = {
  [`${actionTypes.GET_HISTORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getHistoryLoading: true,
    getHistoryError: false,
    getHistorySuccess: false,
  }),
  [`${actionTypes.GET_HISTORY}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getHistoryLoading: false,
    getHistoryError: false,
    getHistorySuccess: true,
    getHistoryList: payload.data
  }),
  [`${actionTypes.GET_HISTORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getHistoryLoading: false,
    getHistoryError: true,
    getHistorySuccess: false,
  })
  // get history reducer
};

export default function historyReducer (state=defaultState, action) {
  return (map[action.type] && map[action.type] (state, action)) || state;
}