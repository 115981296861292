import axios from "axios";
import { actionTypes } from "../../../constants/actionTypes";
import { getHeaders } from "../../../utils/getHeaders";
import { API_URL } from "../../../config";


//get all orders list
export const getOrders = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_ORDERS,
    payload: axios({
      method: "GET",
      url: `${API_URL}/orders`,
      headers: getHeaders()
    })
  });
};

//get unpaid orders list
export const getUnpaidOrders = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_UNPAID_ORDERS,
    payload: axios({
      method: "GET",
      url: `${API_URL}/orders/unpaid-orders`,
      headers: getHeaders()
    })
  });
};


//add an order
export const addOrder = ({
  foodDataList,
  orderType,
  paymentType,
  tgUserChatId,
  phoneNumber,
  addressText,
  paid
}) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_ORDER,
    payload: axios({
      method: "POST",
      url: `${API_URL}/orders`,
      headers: getHeaders(),
      data: {
        foodDataList,
        orderType,
        paymentType,
        tgUserChatId,
        phoneNumber,
        addressText,
        paid
      }
    })
  });
};


//cancel order
export const cancelOrder = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.CANCEL_ORDER,
    payload: axios({
      method: "PUT",
      url: `${API_URL}/orders/canceled/${id}`,
      headers: getHeaders()
    })
  });
};


//confirm order
export const confirmOrder = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.CONFIRMED_ORDER,
    payload: axios({
      method: "PUT",
      url: `${API_URL}/orders/confirmed/${id}`,
      headers: getHeaders()
    })
  });
};


//delivered order
export const deliveredOrder = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.DELIVERED_ORDER,
    payload: axios({
      method: "PUT",
      url: `${API_URL}/orders/delivered/${id}`,
      headers: getHeaders()
    })
  });
};

//get unviewed orders
export const getUnviewdOrders = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_UNVIEWED_ORDERS,
    payload: axios({
      method: "GET",
      url: `${API_URL}/orders/get-unviewed-orders`,
      headers: getHeaders()
    })
  });
};

//unviewed orders
export const unViewedOrders = () => (dispatch) => {
  dispatch({
    type: actionTypes.UNVIEWED_ORDERS,
    payload: axios({
      method: "GET",
      url: `${API_URL}/orders/unviewed`,
      headers: getHeaders()
    })
  });
};


//get on progress orders
export const getOnProgressOrders = () => (dispatch) => {
  dispatch({
    type: actionTypes.ON_PROGRESS_ORDERS,
    payload: axios({
      method: "GET",
      url: `${API_URL}/orders/on-progress`,
      headers: getHeaders()
    })
  });
};


//get order by payment id
export const getOrderByPaymentId = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.GET_ORDER_BY_PAYMENT_ID,
    payload: axios({
      method: "GET",
      url: `${API_URL}/orders/payment/${id}`,
      headers: getHeaders()
    })
  });
};


//get order by payment id
export const orderPickedUp = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.ORDER_PICKED_UP,
    payload: axios({
      method: "PUT",
      url: `${API_URL}/orders/picked-up/${id}`,
      headers: getHeaders()
    })
  });
};


//order prepared
export const orderPrepared = ({id, chatId}) => (dispatch) => {
  dispatch({
    type: actionTypes.ORDER_PREPARED,
    payload: axios({
      method: "PUT",
      url: `${API_URL}/orders/prepared/${id}/deliverer/${chatId}`,
      headers: getHeaders()
    })
  });
};


//delete order
export const deleteOrder = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_ORDER,
    payload: axios({
      method: "DELETE",
      url: `${API_URL}/orders/${id}`,
      headers: getHeaders()
    })
  });
};


//clean order
export const cleanOrderCache = () => ({
    type: actionTypes.CLEAN_ORDER_CACHE,
});