import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
    getFoodDataByCategoryIdLoading: false,
    getFoodDataByCategoryIdError: false,
    getFoodDataByCategoryIdSuccess: false,
    getFoodDataByCategoryId: [],

    getFoodDataLoading: false,
    getFoodDataError: false,
    getFoodDataSuccess: false,
    getFoodDataList: [],

    editFoodDataLoading: false,
    editFoodDataError: false,
    editFoodDataSuccess: false,

    addFoodDataLoading: false,
    addFoodDataError: false,
    addFoodDataSuccess: false,

    deleteFoodDataLoading: false,
    deleteFoodDataError: false,
    deleteFoodDataSuccess: false,

    changeAvailabilityLoading:false,
    changeAvailabilityError:false,
    changeAvailabilitySuccess:false,

    // addSuperCategoryLoading: false,
    // addSuperCategoryError: false,
    // addSuperCategorySuccess: false,
    //
    // editSuperCategoryLoading: false,
    // editSuperCategoryError: false,
    // editSuperCategorySuccess: false
};


const map = {
    [`${actionTypes.GET_PRODUCT_ITEMS_BY_CATEGORY_ID}${actionTypes.PENDING}`]: (state) => ({
        ...state,
        getFoodDataByCategoryIdLoading: true,
        getFoodDataByCategoryIdError: false,
        getFoodDataByCategoryIdSuccess: false,
    }),
    [`${actionTypes.GET_PRODUCT_ITEMS_BY_CATEGORY_ID}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
        ...state,
        getFoodDataByCategoryIdLoading: false,
        getFoodDataByCategoryIdError: false,
        getFoodDataByCategoryIdSuccess: true,
        getFoodDataByCategoryId: payload.data
    }),
    [`${actionTypes.GET_PRODUCT_ITEMS_BY_CATEGORY_ID}${actionTypes.REJECTED}`]: (state) => ({
        ...state,
        getFoodDataByCategoryIdLoading: false,
        getFoodDataByCategoryIdError: true,
        getFoodDataByCategoryIdSuccess: false,
    }), // get product item by category id reducer

    [`${actionTypes.GET_PRODUCT_ITEMS_LIST}${actionTypes.PENDING}`]: (state) => ({
        ...state,
        getFoodDataLoading: true,
        getFoodDataError: false,
        getFoodDataSuccess: false,
    }),
    [`${actionTypes.GET_PRODUCT_ITEMS_LIST}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
        ...state,
        getFoodDataLoading: false,
        getFoodDataError: false,
        getFoodDataSuccess: true,
        getFoodDataList: payload.data
    }),
    [`${actionTypes.GET_PRODUCT_ITEMS_LIST}${actionTypes.REJECTED}`]: (state) => ({
        ...state,
        getFoodDataLoading: false,
        getFoodDataError: true,
        getFoodDataSuccess: false,
    }), // get product item reducer

    [`${actionTypes.EDIT_PRODUCT_ITEM}${actionTypes.PENDING}`]: (state) => ({
        ...state,
        editFoodDataLoading: true,
        editFoodDataError: false,
        editFoodDataSuccess: false,
    }),
    [`${actionTypes.EDIT_PRODUCT_ITEM}${actionTypes.FULFILLED}`]: (state) => ({
        ...state,
        editFoodDataLoading: false,
        editFoodDataError: false,
        editFoodDataSuccess: true,
    }),
    [`${actionTypes.EDIT_PRODUCT_ITEM}${actionTypes.REJECTED}`]: (state) => ({
        ...state,
        editFoodDataLoading: false,
        editFoodDataError: true,
        editFoodDataSuccess: false,
    }), // edit product item reducer

    [`${actionTypes.ADD_PRODUCT_ITEM}${actionTypes.PENDING}`]: (state) => ({
        ...state,
        addFoodDataLoading: true,
        addFoodDataError: false,
        addFoodDataSuccess: false,
    }),
    [`${actionTypes.ADD_PRODUCT_ITEM}${actionTypes.FULFILLED}`]: (state) => ({
        ...state,
        addFoodDataLoading: false,
        addFoodDataError: false,
        addFoodDataSuccess: true,
    }),
    [`${actionTypes.ADD_PRODUCT_ITEM}${actionTypes.REJECTED}`]: (state) => ({
        ...state,
        addFoodDataLoading: false,
        addFoodDataError: true,
        addFoodDataSuccess: false,
    }), // edit product item reducer

    [`${actionTypes.DELETE_PRODUCT_ITEM}${actionTypes.PENDING}`]: (state) => ({
        ...state,
        deleteFoodDataLoading: true,
        deleteFoodDataError: false,
        deleteFoodDataSuccess: false,
    }),
    [`${actionTypes.DELETE_PRODUCT_ITEM}${actionTypes.FULFILLED}`]: (state) => ({
        ...state,
        deleteFoodDataLoading: false,
        deleteFoodDataError: false,
        deleteFoodDataSuccess: true,
    }),
    [`${actionTypes.DELETE_PRODUCT_ITEM}${actionTypes.REJECTED}`]: (state) => ({
        ...state,
        deleteFoodDataLoading: false,
        deleteFoodDataError: true,
        deleteFoodDataSuccess: false,
    }), // delete product item reducer

    [`${actionTypes.CHANGE_AVAILABILITY}${actionTypes.PENDING}`]: (state) => ({
        ...state,
        changeAvailabilityLoading:true,
        changeAvailabilityError:false,
        changeAvailabilitySuccess:false,
    }),
    [`${actionTypes.CHANGE_AVAILABILITY}${actionTypes.FULFILLED}`]: (state) => ({
        ...state,
        changeAvailabilityLoading:false,
        changeAvailabilityError:false,
        changeAvailabilitySuccess:true,
    }),
    [`${actionTypes.CHANGE_AVAILABILITY}${actionTypes.REJECTED}`]: (state) => ({
        ...state,
        changeAvailabilityLoading:false,
        changeAvailabilityError:true,
        changeAvailabilitySuccess:false,
    }), // change availability product item reducer


    [`${actionTypes.CLEAN_PRODUCT_ITEM}`]:(state)=>({
        ...state,
        getFoodDataByCategoryIdLoading: false,
        getFoodDataByCategoryIdError: false,
        getFoodDataByCategoryIdSuccess: false,

        editFoodDataLoading: false,
        editFoodDataError: false,
        editFoodDataSuccess: false,

        addFoodDataLoading: false,
        addFoodDataError: false,
        addFoodDataSuccess: false,

        deleteFoodDataLoading: false,
        deleteFoodDataError: false,
        deleteFoodDataSuccess: false,

        changeAvailabilityLoading:false,
        changeAvailabilityError:false,
        changeAvailabilitySuccess:false,
    })
};

export default function productItemReducer (state=defaultState, action) {
    return(map[action.type] && map[action.type] (state, action)) || state;
}