import axios from "axios";
import { actionTypes } from "../../../constants/actionTypes";
import { API_URL } from "../../../config";
import {getHeaders} from "../../../utils/getHeaders";

// post login credentials
export const postLogin = ({
  username, 
  password 
}) => (dispatch) => {
  dispatch({
    type: actionTypes.POST_LOGIN,
    payload: axios({
      method: "POST",
      url: `${API_URL}/auth/login`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        password: password
      }
    })
    .then((res) => {
      if (res && res.data) {
        localStorage.setItem("token", res.data);
      }
    })
  });
};

//logout
export const logOut = () => ({
  type:actionTypes.LOGOUT
});

//get users
export const getUsers = () => ({
  type:actionTypes.GET_USERS,
  payload:axios({
    method:"GET",
    url:`${API_URL}/users`,
    headers:getHeaders()
  })
});

//get roles
export const getRoles = () => ({
  type:actionTypes.GET_ROLES,
  payload:axios({
    method:"GET",
    url:`${API_URL}/users/role`,
    headers:getHeaders()
  })
});

//change role
export const changeRole = ({userId, roleId})=>({
  type:actionTypes.CHANGE_ROLE,
  payload:axios({
    method:"PUT",
    url:`${API_URL}/users/role?roleId=${roleId}&userId=${userId}`,
    headers:getHeaders()
  })
});

//change enable
export const changeEnable = ({userId, enable})=>({
  type:actionTypes.CHANGE_ENABLE,
  payload:axios({
    method:"PUT",
    url:`${API_URL}/users/enable?enable=${enable}&userId=${userId}`,
    headers:getHeaders()
  })
});