import { actionTypes } from "../../../constants/actionTypes";


const defaultState = {
  userLoading: false,
  userError: false,
  userSuccess: false
};


const map = {
  [`${actionTypes.ADD_ADMIN}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    userLoading: true,
    userError: false,
    userSuccess: false
  }),
  [`${actionTypes.ADD_ADMIN}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    userLoading: false,
    userSuccess: true,
    userError: false
  }),
  [`${actionTypes.ADD_ADMIN}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    userLoading: false,
    userSuccess: false,
    userError: true
  })
};


export default function adminsReducer(state=defaultState, action) {
  return(map[action.type] && map[action.type] (state, action)) || state;
}