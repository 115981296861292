import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  getClientsLoading: false,
  getClientsError: false,
  getClientsSuccess: false,
  clientsList: []
};


const map = {
  [`${actionTypes.GET_CLIENTS}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getClientsLoading: true,
    getClientsError: false,
    getClientsSuccess: false
  }),
  [`${actionTypes.GET_CLIENTS}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getClientsLoading: false,
    getClientsError: false,
    getClientsSuccess: true,
    clientsList: payload.data
  }),
  [`${actionTypes.GET_CLIENTS}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getClientsLoading: false,
    getClientsError: true,
    getClientsSuccess: false,
  })
};

export default function clientsReducer(state=defaultState, action) {
  return (map[action.type] && map[action.type] (state, action)) || state;
}