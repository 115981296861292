import { actionTypes } from "../../../constants/actionTypes";

const defaultState = {
  getCategoryByIdLoading: false,
  getCategoryByIdError: false,
  getCategoryByIdSuccess: false,
  categoryByID: [],

  getCategoryLoading: false,
  getCategoryError: false,
  getCategorySuccess: false,

  categoryData: [],
  addCategoryLoading: false,
  addCategoryError: false,
  addCategorySuccess: false,

  editCategoryLoading: false,
  editCategoryError: false,
  editCategorySuccess: false,

  deleteCategoryLoading: false,
  deleteCategoryError: false,
  deleteCategorySuccess: false
};


const map = {
  [`${actionTypes.GET_CATEGORY_BY_ID}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getCategoryByIdLoading: true,
    getCategoryByIdError: false,
    getCategoryByIdSuccess: false,
  }),
  [`${actionTypes.GET_CATEGORY_BY_ID}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getCategoryByIdLoading: false,
    getCategoryByIdError: false,
    getCategoryByIdSuccess: true,
    categoryByID: payload.data
  }),
  [`${actionTypes.GET_CATEGORY_BY_ID}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getCategoryByIdLoading: false,
    getCategoryByIdError: true,
    getCategoryByIdSuccess: false,
  }),
  // category by ID reducer

  [`${actionTypes.GET_CATEGORY_LIST}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    getCategoryLoading: true,
    getCategoryError: false,
    getCategorySuccess: false,
  }),
  [`${actionTypes.GET_CATEGORY_LIST}${actionTypes.FULFILLED}`]: (state, {payload}) => ({
    ...state,
    getCategoryLoading: false,
    getCategoryError: false,
    getCategorySuccess: true,
    categoryListData: payload.data
  }),
  [`${actionTypes.GET_CATEGORY_LIST}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    getCategoryLoading: false,
    getCategoryError: true,
    getCategorySuccess: false,
  }),
  //category list reducer

  [`${actionTypes.ADD_CATEGORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    addCategoryLoading: true,
    addCategoryError: false,
    addCategorySuccess: false,
  }),
  [`${actionTypes.ADD_CATEGORY}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    addCategoryLoading: false,
    addCategoryError: false,
    addCategorySuccess: true,
  }),
  [`${actionTypes.ADD_CATEGORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    addCategoryLoading: false,
    addCategoryError: true,
    addCategorySuccess: false,
  }), // add category reducer

  [`${actionTypes.EDIT_CATEGORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    editCategoryLoading: true,
    editCategoryError: false,
    editCategorySuccess: false,
  }),
  [`${actionTypes.EDIT_CATEGORY}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    editCategoryLoading: false,
    editCategoryError: false,
    editCategorySuccess: true,
  }),
  [`${actionTypes.EDIT_CATEGORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    editCategoryLoading: false,
    editCategoryError: true,
    editCategorySuccess: false,
  }), //edit category reducer

  [`${actionTypes.DELETE_CATEGORY}${actionTypes.PENDING}`]: (state) => ({
    ...state,
    deleteCategoryLoading: true,
    deleteCategoryError: false,
    deleteCategorySuccess: false,
  }),
  [`${actionTypes.DELETE_CATEGORY}${actionTypes.FULFILLED}`]: (state) => ({
    ...state,
    deleteCategoryLoading: false,
    deleteCategoryError: false,
    deleteCategorySuccess: true,
  }),
  [`${actionTypes.DELETE_CATEGORY}${actionTypes.REJECTED}`]: (state) => ({
    ...state,
    deleteCategoryLoading: false,
    deleteCategoryError: true,
    deleteCategorySuccess: false,
  }), //delete category reducer

  [actionTypes.CLEAN_CATEGORY_CACHE]:(state)=>({
    ...state,
    addCategoryLoading: false,
    addCategoryError: false,
    addCategorySuccess: false,

    editCategoryLoading: false,
    editCategoryError: false,
    editCategorySuccess: false,

    deleteCategoryLoading: false,
    deleteCategoryError: false,
    deleteCategorySuccess: false
  })
};

export default function categoryReducer (state=defaultState, action) {
  return(map[action.type] && map[action.type] (state, action)) || state;
}