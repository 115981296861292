import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
// Bootstrap style
import "bootstrap/dist/css/bootstrap.min.css";
// Ant Design style
import "antd/dist/antd.css";

// store
import Store from "./redux/store/store";

ReactDOM.render(
  <Router>
    <Provider store={Store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
